<template>
  <div class="capabilities-container">
    <CapabilityCard
      v-for="(capability, index) in capabilities"
      v-bind:capability="capability"
      v-bind:key="index"
      :showNotLinked="showNotLinked"
    />
  </div>
</template>

<script>
import CapabilityCard from "@/components/CapabilityCard";

export default {
  name: "Capabilities",
  data: () => ({
    showNotLinked: false
  }),
  computed: {
    capabilities: function() {
      return this.$store.state.shared.capabilities;
    },
    activeBrand: function() {
      return this.$store.state.shared.activeBrand;
    }
  },
  components: {
    CapabilityCard
  },
  mounted() {
    this.showNotLinked = false;
    this.checkNotLinked();
  },
  watch: {
    activeBrand: function() {
      this.showNotLinked = false;
      this.checkNotLinked();
    }
  },
  methods: {
    checkNotLinked: function() {
      let brandId = this.activeBrand.slug;

      this.$apiClient
        .send(this, "get", `connectors/config-status/brand/${brandId}`)
        .then(response => {
          this.showNotLinked = false;

          // Check that we got data back
          if (response.data) {
            // Get the config status for this connector
            for (let i = 0; i < response.data.length; i++) {
              let status = response.data[i];

              const restaurantKeys = Object.keys(status.restaurants_linked);

              // Determine if there is even one restaurant not linked for this selected brand for any connector
              for (let j = 0; j < restaurantKeys.length; j++) {
                const restaurantKey = restaurantKeys[j];

                if (!status.restaurants_linked[restaurantKey]) {
                  this.showNotLinked = true;
                  break;
                }
              }
            }
          }
        })
        .catch(error => {
          console.warn(
            "Unable to get connector config status for brand",
            error
          );
        });
    }
  }
};
</script>
