<template>
  <v-sheet class="pa-6 mb-4">
    <h3 class="text-xl py-2 font-semibold pb-5">
      {{ capability.title }}
    </h3>
    <p>
      {{ capability.description }}
    </p>
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="con-temp-button-overrides"
        color="primary"
        :to="{ name: button.route }"
        v-for="button in capability.buttons"
        v-bind:key="button.id"
      >
        {{ button.label || "Configure" }}
      </v-btn>
      <span
        v-if="showNotLinked"
        class="con-warning--text d-flex align-center mr-2"
      >
        Some restaurants not linked!
      </span>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "CapabilityCard",
  props: {
    capability: Object,
    showNotLinked: Boolean
  }
};
</script>
